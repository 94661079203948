<template>
  <!-- {{ allocations }} -->
  <div v-for="location in allocations" :key="location.id" style="margin-bottom: 50px;">
    <div>
      <!-- <img height="50"
          src="https://upload.wikimedia.org/wikipedia/en/thumb/3/34/Nemesis_Inferno_Logo.jpg/250px-Nemesis_Inferno_Logo.jpg" /> -->
      <h3 :style="['color:' + location.color]">{{ location.name }}</h3>
    </div>

    <DataTable :value="location.allocations" editMode="cell" @cell-edit-complete="onCellEditComplete"
      responsiveLayout="scroll">
      <Column field="staffName" header="Team Member"></Column>
      <Column field="position" header="Position" style="width: 20%; cursor: pointer;">
        <template #body="slotProps">
          {{ location.positions.find(position => position.id === slotProps.data.positionID).name }}
          <i class="pi pi-fw pi-pencil" />
        </template>
        <template #editor="slotProps">
          <Dropdown v-model="slotProps.data.positionID" :options="location.positions" optionLabel="name"
            optionValue="id" placeholder="Select a Position" />
        </template>
      </Column>
      <Column field="note" header="Note"></Column>
      <Column style="width: 100px;">
        <template #body>
          <i class="pi pi-fw pi-trash" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
// import { ref } from 'vue';
export default {
  components: {
    DataTable,
    Column,
    Dropdown
  },
  props: {
    allocations: {
      type: Array,
      default: null
    }
  },
  setup() {

    function onCellEditComplete(event) {

      let { data, newData, field } = event
      switch (field) {
        case 'position':
          data.positionID = newData.positionID
          break;

        default:
          break;
      }

    }

    return { onCellEditComplete }
  }
}

</script>

<style>
td.p-cell-editing {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>