import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../views/MainLayout.vue';
import Allocations from '../views/Allocations.vue';
import Team from '../views/Team.vue';
import Settings from '../views/Settings.vue';
import Locations from '../views/Locations.vue';

const routes = [
  {
    path: '',
    component: MainLayout,
    children: [
      // {
      //   path: '/home',
      //   name: 'Home',
      //   component: Home,
      // },
      {
        path: '/allocations',
        name: 'Allocations',
        component: Allocations,
      },
      {
        path: '/team',
        name: 'Team',
        component: Team,
      },
      {
        path: '/locations',
        name: 'Locations',
        component: Locations,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
