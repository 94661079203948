<template>
  <TabMenu :model="menuItems" style="margin-bottom: 40px;" />
  <router-view />
</template>

<script>
import { ref } from 'vue';
import TabMenu from 'primevue/tabmenu';

export default {
  components: {
    TabMenu
  },
  setup() {
    const menuItems = ref([
      {
        label: 'Allocations',
        icon: 'pi pi-fw pi-calendar-plus',
        to: '/allocations'
      },
      {
        label: 'Team',
        icon: 'pi pi-fw pi-users',
        to: '/team'
      },
      {
        label: 'Locations',
        icon: 'pi pi-fw pi-map-marker',
        to: '/locations'
      },
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        to: '/settings'
      }
    ]);
    return { menuItems }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
