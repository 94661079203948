<template>
  <!-- <h1>Allocations</h1> -->
  <div>
    <Calendar id="basic" v-model="periodSelected" autocomplete="on" dateFormat="dd/mm/yy" :date="new Date()"
      style="margin-right: 10px;" />
    <MultiSelect v-model="selectedAreas" :options="areas" optionLabel="name" placeholder="Area filter"
      style="margin-right: 10px;" />
    <MultiSelect v-model="selectedLocations" :options="locationsFiltered" optionLabel="name"
      placeholder="Location filter" emptyMessage="Select an area first" />
  </div>
  <div style="margin-top: 50px;">
    <AllocationsTable :allocations="allocationsFiltered" style="margin-top: 20px;" />
  </div>
</template>

<script>
// import Card from 'primevue/card';
import { computed } from '@vue/reactivity';
import { ref } from 'vue';

import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import AllocationsTable from '@/components/AllocationsTable.vue'

export default {
  components: {
    Calendar,
    MultiSelect,
    AllocationsTable
    // Card
  },
  setup() {
    const periodSelected = ref(new Date())
    const areas = ref([
      { name: 'Area 1', id: 1 },
      { name: 'Area 2', id: 2 },
      { name: 'Area 3', id: 3 },
    ])
    const selectedAreas = ref([])

    const locations = ref([
      { name: 'Nemesis Inferno', id: 1, color: '#b80000', areaID: 2, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
      { name: 'Saw: The Ride', id: 2, color: '#bdbdbd', areaID: 2, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
      { name: 'Mr. Monkey\'s Banana Ride', color: '#f0c846', id: 3, areaID: 2, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
      { name: 'Derren Brown\'s Ghost Train', color: '#6b5041', id: 4, areaID: 2, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
      { name: 'The Rocky Express', color: '#5bc0de', id: 5, areaID: 2, positions: [{ id: 1, name: 'OP' }] },
      { name: 'The Swarm', id: 6, color: '#7d7d7d', areaID: 3, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
      { name: 'Colossus', id: 7, color: '#8ad4cb', areaID: 1, positions: [{ id: 1, name: 'OP' }, { id: 2, name: 'HOST' }] },
    ])
    const selectedLocations = ref([])

    const locationsFiltered = computed(() => {

      return locations.value.filter((location) => {
        return selectedAreas.value.some((area) => {
          return area.id === location.areaID
        })
      })

    })

    const allocationsArray = ref([
      { id: 1, staffName: 'Max Resnikoff', locationID: 1, positionID: 1, note: null },
      { id: 2, staffName: 'Kieran Deas', locationID: 4, positionID: 1, note: null },
      { id: 3, staffName: 'Jake McDonald', locationID: 4, positionID: 2, note: null },
      { id: 3, staffName: 'Louie Purdey', locationID: 5, positionID: 1, note: null }
    ])

    const allocationsFiltered = computed(() => {
      let allocated = ref([])
      selectedLocations.value.forEach(location => {

        location.allocations = allocationsArray.value.filter((allocation) => allocation.locationID === location.id)

        allocated.value.push(location)
      })
      return allocated.value
    })

    return { periodSelected, areas, selectedAreas, locations, locationsFiltered, selectedLocations, allocationsFiltered }

  }
}
</script>

<style>
</style>
